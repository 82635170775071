import { Tabs } from 'antd';
import React from 'react';

import { ContentWrapper, SettingsNavigation } from '~components';
import { CategoriesList } from '~containers';
import { HeaderPreviewsList } from '~containers/HeaderPreview';
import { HeadingsList } from '~containers/Heading';
import { SubcategoriesList } from '~containers/Subcategory';
import { useFetchPaginationStore, useFilterParamsByListStore, useUserStore } from '~hooks';

import { SectionsList } from '../../containers/Sections';
import s from './styles.scss';

const PAGE_TITLE = 'Настройка разделов и категорий на сайте';
const { TabPane } = Tabs;

const SettingsCategoriesSectionsPage = () => {
  const { setFilterParams } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const { checkPermissions } = useUserStore();

  const handleOnTabClick = () => {
    setFilterParams();
    resetPaginationParams();
  };
  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        <div className={s.title}>Выберете раздел</div>
        <Tabs type="card" destroyInactiveTabPane onTabClick={handleOnTabClick}>
          {checkPermissions('categories.view') && (
            <TabPane tab="Категории" key="1">
              <CategoriesList />
            </TabPane>
          )}
          {checkPermissions('subcategories.view') && (
            <TabPane tab="Подкатегории" key="2">
              <SubcategoriesList />
            </TabPane>
          )}
          {checkPermissions('sections.view') && (
            <TabPane tab="Разделы" key="3">
              <SectionsList />
            </TabPane>
          )}
          {checkPermissions('headings.view') && (
            <TabPane tab="Рубрики" key="4">
              <HeadingsList />
            </TabPane>
          )}
          {checkPermissions('headerPreviews.view') && (
            <TabPane tab="Превью для хедера" key="5">
              <HeaderPreviewsList />
            </TabPane>
          )}
        </Tabs>
      </ContentWrapper>
    </>
  );
};

export default SettingsCategoriesSectionsPage;
