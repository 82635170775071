import { notification } from 'antd';
import { v0 } from 'life-schema';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import values from 'lodash/values';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import moment from 'moment';
import {
  allPass,
  always,
  anyPass,
  apply,
  complement,
  cond,
  dissoc,
  eqBy,
  equals,
  evolve,
  filter,
  flatten,
  forEachObjIndexed,
  fromPairs,
  has,
  identity,
  ifElse,
  includes,
  invoker,
  isNil,
  juxt,
  keys,
  length,
  map,
  mapObjIndexed,
  mergeAll,
  mergeDeepLeft,
  not,
  omit,
  path,
  pipe,
  prop,
  propEq,
  propOr,
  symmetricDifferenceWith,
  T,
  tryCatch,
} from 'ramda';

import {
  CLICKBAIT_BLOCK,
  COVER_BLOCK,
  EMPTY_STRING,
  SPECIAL_TITLE_BLOCK,
  SUBTITLE_BLOCK,
  TEXT_DESCRIPTION,
  TITLE_BLOCK,
} from '~constants';
import { resourceTypes } from '~constants/publication';
import { templateRestoreModal } from '~pages/EditorPage/components/TemplateRestoreModal';
import { openValidationModal } from '~pages/EditorPage/components/ValidationModal';

import { castByHeadBlockSchema, castBySettingsBlockSchema } from '~schemas';
import API from '~services/api';
import { PublicationTransportLayer } from '~services/publicationApi';
import DraftStore from '~store/DraftStore';
import PublicationCommentsStore from '~store/PublicationCommentsStore';
import StatusStore from '~store/StatusStore';
import {
  callAsync,
  debounce,
  getSettingsNormalizedData,
  isCanceledRequest,
  isExistsAuthors,
  isNotNil,
  promiseAll,
  timeout,
} from '~utils';
import {
  getErrorMessage,
  showErrorNotification,
  showErrorNotificationInPromise,
} from '~utils/getError';
import OverlayStore from '../OverlayStore';
import UserStore from '../UserStore';
import BlockGroup from './BlockGroupModel';

import Block from './BlockModel';

import PriorityStore from './PostPriorityStore';

const PUBLICATION_INTERVAL = 10000;

const TEMPLATE_INTERVAL = 5000;

notification.config({ placement: 'bottomRight' });

export class PublicationStore {
  /**
   *@type {PublicationTransportLayer}
   */
  apiLayer = new PublicationTransportLayer();

  symbols = {
    mediaCaption: 0,
    subtitle: 0,
    title: 0,
    clickbait: 0,
    types: {},
  };

  publication = {};

  priorities = new PriorityStore(this);

  @observable initialized = false;

  @observable isPublicationSaving = false;

  @observable isPublicationValidating = false;

  @observable savingModal = false;

  @observable savingError = false;

  @observable isNew = false;

  @observable usersOnPage = [];

  @observable isLoading = false;

  @observable isShowSettingsDrawer = false;

  @observable loadingMessage = '';

  @observable dictionaries = {
    dictionaries: {
      postTypes: [],
      postAdvTypes: [],
      postPriorities: [],
      postStatuses: [],
      timers: [],
      // yandexTags: [],
    },
    categories: [],
    subcategories: [],
    sections: [],
    tags: [],
    regions: [],
    authors: [],
  };

  @observable resourceType = '';

  @observable rssTypes = [];

  @observable status = { color: '', ru: '', id: '' };

  @observable id = '';

  @observable updatedAt = '';

  @observable createdAt = '';

  @observable start = '';

  @observable end = '';

  @observable note = '';

  @observable publicationDate = '';

  @observable createdBy = { firstName: '', lastName: '', avatar: '' };

  @observable updatedBy = { firstName: '', lastName: '', avatar: '' };

  @observable validatedBy = { corrector: false };

  @observable validatedAt = { corrector: '' };

  @observable alias = '';

  @observable index = '';

  @observable type = '';

  @observable categories = [];

  @observable subcategories = [];

  @observable section = '';

  @observable priority = '';

  @observable tags = [];

  @observable regions = [];

  @observable flows = [];

  @observable authors = [];

  @observable coauthors = [];

  @observable advType = '';

  @observable yandexTag = '';

  @observable newsLinks = [];

  @observable flags = {
    commentsAllowed: true,
    advDisabled: false,
    pushAllowed: false,
    adultContent: false,
    criminalContent: false,
    // blockedByRKN: false,
    // darkSide: false,
    // isWide: false,
    RSS: {
      brandAnalytics: false,
      exclusives: false,
      googleEditorsChoice: false,
      googleNews: false,
      googleNewsStand: false,
      instantFB: false,
      // mailruNews: false,
      // mailruPulse: false,
      // mailruPulsePromo: false,
      mainFeed: false,
      yandexDzen: false,
      yandexDzenNative: false,
      yandexNews: false,
      yandexNewsArticle: false,
      yandexNewsSpb: false,
    },
  };

  @observable token = '';

  @observable settingsLocked = null;

  @observable blockGroups = observable.array();

  @observable isOpenModalPriority = false;

  @observable publicationBlocksContent = new Map([]);

  @observable mandatoryBlocksContent = new Map([]);

  @observable publicationBlocksOrder = observable.array();

  @observable fetchingRequests = observable.array();

  @observable publicationTimer = null;

  @observable template = null;

  @observable savings = {
    settings: { status: 'ok' },
    moderation: { status: 'ok' },
    blocks: {
      isSaving: false,
      errors: [],
    },
  };

  @observable validationResult = {
    blockValidationErrors: {
      globalError: null,
      validationErrors: [],
    },
    fieldsValidationErrors: {
      validationErrors: [],
    },
  };

  @observable isForceUpdate = {
    cover: false,
    coverTitle: false,
    title: false,
    subtitle: false,
    clickbait: false,
  };

  @computed get isNewsSection() {
    const currentSection = this.dictionaries.sections.find(section => this.section === section._id);
    return currentSection?.alias === 'novosti' || currentSection?.alias === 'dich';
  }

  @computed get blocks() {
    return [...this.publicationBlocksContent.values()].sort(
      ({ id: left }, { id: right }) =>
        this.publicationBlocksOrder.indexOf(left) - this.publicationBlocksOrder.indexOf(right),
    );
  }

  @computed get mandatoryBlocks() {
    return [...this.mandatoryBlocksContent.values()].sort(
      ({ type: left }, { type: right }) =>
        this.mandatoryFieldArray.indexOf(left) - this.mandatoryFieldArray.indexOf(right),
    );
  }

  @computed get asJson() {
    return {
      resourceType: this.resourceType,
      type: this.type,
      alias: this.alias,
      categories: this.categories,
      subcategories: this.subcategories,
      section: this.section,
      priority: this.priority,
      tags: this.tags,
      flows: this.flows,
      authors: this.authors,
      coauthors: this.coauthors,
      advType: this.advType,
      // yandexTag: this.yandexTag,
      regions: this.regions,
      flags: omit(['coverHidden'], this.flags),
      // start: this.start, // TODO: вернуть, иначе будут проблемы с трансляциями
      // end: this.end,
      note: this.note,
      // newsLinks: this.newsLinks,
      draftPriorities: this.priorities.draftPriorities,
    };
  }

  @computed get isQuiz() {
    return equals(resourceTypes.quiz, this.resourceType);
  }

  @computed get settings() {
    return {
      type: this.type,
      categories: this.categories,
      subcategories: this.subcategories,
      section: this.section,
      priority: this.priority,
      tags: this.tags,
      flows: this.flows,
      authors: this.authors,
      coauthors: this.coauthors,
      advType: this.advType,
      // yandexTag: this.yandexTag,
      regions: this.regions,
      flags: omit(['coverHidden'], this.flags),
      alias: this.alias,
      // newsLinks: this.newsLinks,
    };
  }

  @computed get blocksArray() {
    return toJS(this.blocks, { recurseEverything: true });
  }

  @computed get mandatoryFieldArray() {
    let fieldsArray = [
      'COVER',
      'CLICKBAIT',
      'TITLE',
      'SPECIAL_TITLE',
      'SUBTITLE',
      'QUIZ',
      'TEXT_DESCRIPTION',
    ];

    if (this.isNewsSection) {
      fieldsArray = [
        'COVER',
        'SPECIAL_TITLE',
        'CLICKBAIT',
        'TITLE',
        'SUBTITLE',
        'QUIZ',
        'TEXT_DESCRIPTION',
      ];
    }

    return fieldsArray.filter(
      ifElse(
        always(equals(this.resourceType, resourceTypes.publication)),
        pipe(equals('QUIZ'), not),
        T,
      ),
    );
  }

  @computed get mandatoryBlocksArray() {
    return toJS(this.mandatoryBlocks, { recurseEverything: true });
  }

  @computed get isShowNotifyPlannedPost() {
    const currentDate = moment();
    const postDate = moment(this.publicationDate);
    return moment(postDate).isBefore(currentDate);
  }

  @computed get shouldLimitTitles() {
    const createdAt = moment(this.createdAt);
    const limitTitlesDate = moment(StatusStore.status?.settings?.limitTitlesDate, 'YYYY-MM-DD');
    return moment(createdAt).isSameOrAfter(limitTitlesDate);
  }

  @computed get flagsJson() {
    return toJS(this.flags);
  }

  @computed get rssJson() {
    return Object.entries(toJS(this.flags.RSS))
      .filter(([, value]) => value)
      .map(([key]) => key);
  }

  @computed get dictionariesList() {
    return {
      ...this.dictionaries,
      categories: [
        ...this.dictionaries.categories.filter(
          ({ _id, visible }) => this.categories.findIndex(id => id === _id) > -1 || visible,
        ),
      ],
      subcategories: [
        ...this.dictionaries.subcategories.filter(
          ({ _id, visible }) => this.subcategories.findIndex(id => id === _id) > -1 || visible,
        ),
      ],
      sections: [
        ...this.dictionaries.sections.filter(
          ({ _id, visible }) => (this.section || {})._id === _id || visible,
        ),
      ],
    };
  }

  @computed get flagsArray() {
    return Object.keys(this.flags).filter(flag => this.flags[flag]);
  }

  @computed get textCount() {
    const customBlockType = ['card:image', 'person', 'card:text', 'card:quote'];

    const blocksTextCount = [...this.blocks, ...this.mandatoryBlocks]
      .map(({ data: { charactersCount = 0, customType, values: fieldValues = {}, type } }) => {
        if (type === 'WIDGET') {
          return 0;
        }

        if (!customType) {
          return charactersCount;
        }

        if (customBlockType.indexOf(customType) !== -1) {
          const counts = Object.keys(fieldValues).reduce((memo, key) => {
            const count = (fieldValues[key].blocks || [])
              .map(item => item.text.length)
              .reduce((acc, v) => acc + v, 0);

            return Number(memo) + Number(count);
          }, 0);

          return counts;
        }

        if (customType === 'gigarama') {
          return values(fieldValues)
            .map(item => (typeof item === 'string' ? item.length : 0))
            .reduce((acc, v) => acc + v, 0);
        }

        return 0;
      })
      .reduce((acc, v) => acc + v, 0);

    return blocksTextCount;
  }

  @computed get isFetching() {
    return (
      this.isPublicationSaving ||
      this.isPublicationValidating ||
      this.fetchingRequests.length > 0 ||
      [...this.publicationBlocksContent.values(), ...this.mandatoryBlocksContent.values()].filter(
        blockModel => blockModel.lockProcessing,
      ).length > 0
    );
  }

  @computed get settingsBlocked() {
    if (this.settingsLocked) {
      const { lockedBy = null, lockedTo = null } = this.settingsLocked;
      return {
        blocked: this.isLockedByOthers(this.settingsLocked),
        isEditing: this.isLockedByMe(this.settingsLocked),
        lockedBy,
        lockedTo,
      };
    }
    return {};
  }

  @computed get isPublicationLockedByMe() {
    return [...this.blocks, ...this.mandatoryBlocks, this.settingsLocked].some(this.isLockedByMe);
  }

  @computed get me() {
    return UserStore?.user || null;
  }

  @computed get publicationSavings() {
    return [
      {
        ...this.savings.settings,
        title: 'Настройки',
      },
      {
        ...this.savings.blocks,
        status: this.savings.blocks.errors.length > 0 ? 'error' : 'ok',
        title: 'Блоки',
        error: this.savings.blocks.errors,
      },
      {
        ...this.savings.moderation,
        title: 'Публикация',
      },
    ];
  }

  @computed get isPostPriorityHidden() {
    return this.priority === 'POST_PRIORITY_HIDDEN';
  }

  @action
  setDictionaries = (dictionaries = {}) => {
    this.dictionaries = dictionaries;
  };

  @action
  setSymbols = (symbols = {}) => {
    this.symbols = symbols;
  };

  @action
  setRssTypes = (rssTypes = []) => {
    this.rssTypes = rssTypes;
  };

  @action
  setInitialized = initialized => {
    this.initialized = initialized;
  };

  @action
  setIsLoading = isLoading => {
    this.isLoading = isLoading;
  };

  @action
  setIsPublicationSaving = isPublicationSaving => {
    this.isPublicationSaving = isPublicationSaving;
  };

  @action
  setIsPublicationValidating = isPublicationValidating => {
    this.isPublicationValidating = isPublicationValidating;
  };

  @action
  showModalPriority = async () => {
    /**
     * draftPriorities - из БД, realPriorities - реально положение в сетках
     */
    if (this.priorities.draftPriorities) {
      this.isOpenModalPriority = true;
      return;
    }
    this.apiLayer
      .getPostPriorities(this.id)
      .then(({ draftPriorities = null, realPriorities = null }) => {
        this.priorities.update({ draftPriorities, realPriorities });
        this.isOpenModalPriority = true;
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка загрузки публикаций' }));
  };

  @action
  hideModalPriority = () => {
    this.isOpenModalPriority = false;
  };

  @action
  unlockSettingsOnRouteChange = () => {
    if (this.isLockedByMe(this.settingsLocked)) {
      this.apiLayer.unlockSettingsWithBeacon();
    }
  };

  @action
  unlockSettingsLocal = () => {
    if (this.isLockedByMe(this.settingsLocked)) {
      this.settingsLocked = { lockedBy: null, lockedTo: null, hash: '' };
    }
  };

  isSettingsLocked = () => {
    if (this.settingsLocked) {
      if (this.settingsLocked.lockedBy) {
        return true;
      }
    }
    return false;
  };

  isFieldFetching = (...fields) => this.fetchingRequests.some(field => fields.includes(field));

  isFieldForceUpdate = field => !!this.isForceUpdate[field];

  @action
  setSettingsLocked = locks => {
    this.settingsLocked = locks;
  };

  @action
  lockSettings = (withSync = true) => {
    if (!this.isSettingsLocked()) {
      this.setFetching('settings');
      this.apiLayer.cancelFetchPublication();
      return this.apiLayer
        .lockSettings()
        .then(async publication => {
          if (withSync) {
            await this.syncFromServer(publication);
          } else {
            const settingsLocked = publication?.settingsLocked || {
              lockedBy: null,
              lockedTo: null,
              hash: '',
            };
            this.setSettingsLocked(settingsLocked);
          }
          return Promise.resolve(publication);
        })
        .catch(e => {
          showErrorNotification('Ошибка блокировки', e);
          return Promise.reject(e);
        })
        .finally(() => {
          this.setFetchingComplete('settings');
        });
    } else {
      return Promise.resolve(true);
    }
  };

  @action
  unlockSettings = () => {
    this.setFetching('settings');
    this.apiLayer.cancelFetchPublication();
    return this.apiLayer
      .unlockSettings()
      .then(async publication => {
        const settingsLocked = publication?.settingsLocked || {
          lockedBy: null,
          lockedTo: null,
          hash: '',
        };
        this.setSettingsLocked(settingsLocked);
        return Promise.resolve(publication);
      })
      .catch(e => {
        showErrorNotification('Ошибка разблокировки', e);
        return Promise.reject(e);
      })
      .finally(() => {
        this.setFetchingComplete('settings');
      });
  };

  @action
  lockField = ({ field, withSync = true }) => {
    if (!this.isFieldLocked({ field })) {
      this.setFetching(field);
      this.apiLayer.cancelFetchPublication();
      return this.apiLayer
        .lockField({ field })
        .then(async publication => {
          if (withSync) {
            await this.syncFromServer(publication);
          } else {
            const locks = publication?.locks || [];
            this.setLocks(locks);
          }
          return Promise.resolve(publication);
        })
        .catch(e => {
          showErrorNotification('Ошибка блокировки', e);
          return Promise.reject(e);
        })
        .finally(() => {
          this.setFetchingComplete(field);
        });
    } else {
      return Promise.resolve(true);
    }
  };

  @action
  unlockField = ({ field }) => {
    this.setFetching(field);
    this.apiLayer.cancelFetchPublication();
    return this.apiLayer
      .unlockField({ field })
      .then(publication => {
        const locks = publication?.locks || [];
        this.setLocks(locks);
        return Promise.resolve(publication);
      })
      .catch(e => {
        showErrorNotification('Ошибка разблокировки', e);
        return Promise.reject(e);
      })
      .finally(() => {
        this.setFetchingComplete(field);
      });
  };

  @action
  init = async ({ type, id }) => {
    this.clearPublication();
    try {
      const dictionaries = await this.apiLayer.getDictionaries();

      this.setDictionaries(dictionaries);
      // console.log('finished loading getDictionaries...', dictionaries);
    } catch (reason) {
      showErrorNotification('Ошибка загрузки справочников', reason);
      return Promise.reject(false);
    }

    try {
      const symbols = await this.apiLayer.getSymbols();
      this.setSymbols(symbols);
      // console.log('finished loading symbols...', symbols);
    } catch (reason) {
      showErrorNotification('Ошибка загрузки настроек количества символов', reason);
      return Promise.reject(false);
    }

    try {
      const { rssTypes = [] } = await this.apiLayer.getSettingsDictionaries();
      // console.log('finished loading rssTypes...', rssTypes);
      this.setRssTypes(rssTypes);
    } catch (reason) {
      showErrorNotification('Ошибка загрузки справочника настроек', reason);
      return Promise.reject(false);
    }

    try {
      const publication = await this.loadPublication({ id, type });

      const { _id: publicationId } = publication;
      // console.log('finished loading loadPublication...');
      this.apiLayer.id = publicationId;
      this.priorities.init();
      this.setPublicationData(publication);
    } catch (reason) {
      showErrorNotification('Ошибка загрузки публикации', reason);
      return Promise.reject(false);
    }

    try {
      const usersOnPage = await this.apiLayer.getEmployeesOnPage();
      // console.log('finished loading getEmployeesOnPage...', usersOnPage);
      this.setUsersOnPage(usersOnPage);
    } catch (reason) {
      showErrorNotification('Ошибка загрузки пользователей на публикации', reason);
      return Promise.reject(false);
    }

    this.setInitialized(true);
    // console.log('finished loading...');

    this.startPublicationTimer();

    runInAction(() => {
      this.template = this.getTemplate();
    });
    return Promise.resolve(true);
  };

  /**
   * Fetches publication data from the server
   */
  @action
  loadPublication({ id, type }) {
    if (id === 'createMaterial') {
      this.isNew = true;
      return this.apiLayer.createPublication(type);
    }
    if (id === 'createNews') {
      this.isNew = true;
      const section = this.dictionaries.sections.find(section => section.alias === 'novosti');
      return this.apiLayer.createPublication(type, section?._id);
    }

    return this.apiLayer.fetchPublication(id);
  }

  @action
  refreshPublication = async () => {
    // console.log("refreshPublication...");
    try {
      if (!this.isFetching && this.id) {
        const publication = await this.apiLayer.fetchPublication(this.id);
        const users = await this.apiLayer.getEmployeesOnPage();
        if (!this.isFetching) {
          await this.syncFromServer(publication);
          this.setUsersOnPage(users);
        }
      }
    } catch (e) {
      if (!isCanceledRequest(e)) {
        showErrorNotification('Ошибка обновления публикации', e, 30);
      }
    }

    this.startPublicationTimer();
  };

  @action
  syncFromServer = async publication => {
    // console.log("syncFromServer...", publication);

    const { blockGroups = [], blocks = [], settingsLocked = null } = publication;

    const settingsLockedByMe = this.isLockedByMe(settingsLocked);
    const localSettingsLockedByMe = this.isLockedByMe(this.settingsLocked);

    this.blockGroups.replace(
      blockGroups
        .map(blockGroup =>
          evolve({
            blocks: map(
              ifElse(
                allPass([this.isLocked, this.isLockedByMe]),
                this.setBlockDataFromGroupStore(blockGroup),
                identity,
              ),
            ),
          })(blockGroup),
        )
        .map(this.createBlockGroupModel),
    );

    const blocksContent = new Map([]);
    const mandatoryBlocksContent = new Map([]);
    const blocksOrder = blocks
      .filter(block => !this.mandatoryFieldArray.includes(block.type))
      .map(this.getServerId);

    const blocksLockedByMe = blocks.filter(this.isLockedByMe).map(this.setBlockDataFromStore);
    const blocksNotLocked = blocks.filter(this.isNotLocked);
    const blocksLockedByOthers = blocks.filter(this.isLocked).filter(this.isLockedByOthers);

    const blocksModel = [...blocksLockedByMe, ...blocksNotLocked, ...blocksLockedByOthers].map(
      this.createBlockModel,
    );
    blocksModel.forEach(blockModel => {
      if (this.mandatoryFieldArray.includes(blockModel.type)) {
        mandatoryBlocksContent.set(this.getId(blockModel), blockModel);
      } else {
        blocksContent.set(this.getId(blockModel), blockModel);
      }
    });

    /** @type {Block[]} */
    const localBlocksLockedByMe = [
      ...this.blocks.filter(this.isLockedByMe),
      ...this.mandatoryBlocks.filter(this.isLockedByMe),
    ];
    const blocksForDraft = localBlocksLockedByMe.filter(localBlock => {
      const serverBlock = blocks.find(
        serverBlock => this.getServerId(serverBlock) === this.getId(localBlock),
      );
      return this.isLockedByMe(localBlock) && this.isNotLockedByMe(serverBlock);
    });

    const settingsLockedForDraft = localSettingsLockedByMe && !settingsLockedByMe;

    if (blocksForDraft.length > 0 || settingsLockedForDraft) {
      DraftStore.setDraft(blocksForDraft, settingsLocked);
    }

    this.publicationBlocksContent.replace(blocksContent);
    this.publicationBlocksOrder.replace(blocksOrder);
    this.mandatoryBlocksContent.replace(mandatoryBlocksContent);

    this.setSettingsLocked(settingsLocked);

    const fieldsToRefresh = castByHeadBlockSchema(publication);

    if (!settingsLockedByMe) {
      Object.assign(fieldsToRefresh, castBySettingsBlockSchema(publication));
    }

    for (const [field, value] of Object.entries(fieldsToRefresh)) {
      this[field] = value;
    }
  };

  @action
  setPublicationData = publication => {
    const {
      blockGroups = [],
      blocks = [],
      settingsLocked = { lockedBy: null, lockedTo: null },
    } = publication;
    const fieldsToRefresh = {
      ...castByHeadBlockSchema(publication),
      ...castBySettingsBlockSchema(publication),
    };

    // console.log('fieldsToRefresh...', fieldsToRefresh);
    for (const [field, value] of Object.entries(fieldsToRefresh)) {
      this[field] = value;
    }

    const blocksContent = new Map([]);
    const mandatoryBlocksContent = new Map([]);
    const blocksOrder = blocks
      .filter(block => !this.mandatoryFieldArray.includes(block.type))
      .map(this.getServerId);

    blocks.map(this.createBlockModel).forEach(block => {
      if (this.mandatoryFieldArray.includes(block.type)) {
        mandatoryBlocksContent.set(this.getId(block), block);
      } else {
        blocksContent.set(this.getId(block), block);
      }
    });

    this.publicationBlocksContent.replace(blocksContent);
    this.mandatoryBlocksContent.replace(mandatoryBlocksContent);
    this.publicationBlocksOrder.replace(blocksOrder);
    if (settingsLocked) this.settingsLocked = settingsLocked;

    if (blockGroups.length > 0) {
      blockGroups.forEach(group => {
        this.setBlockGroupsFromServer(group);
      });
    }
  };

  @action
  setUsersOnPage(users = []) {
    this.usersOnPage = users;
  }

  @action
  clearPublication = () => {
    this.apiLayer.cancelFetchPublication();
    this.isLoading = false;
    this.initialized = false;
    this.isNew = false;
    this.token = EMPTY_STRING;

    this.usersOnPage = [];
    this.settingsLocked = null;
    this.publicationBlocksContent.clear();
    this.mandatoryBlocksContent.clear();
    this.publicationBlocksOrder.clear();
    this.blockGroups.clear();
    this.setPublicationData({});
    this.clearPublicationTimer();
  };

  @action
  deletePublication = () => {
    this.apiLayer.cancelFetchPublication();
    return this.apiLayer
      .deletePublication(this.id)
      .catch(showErrorNotificationInPromise({ title: 'Ошибка удаления публикации' }));
  };

  getModerationError = cond([
    [equals('bad words contained'), always('присутствуют запрещенные слова')],
    [T, identity],
  ]);

  clearErrorModeration = () => {
    this.savings.moderation.isSaving = true;
    this.savings.moderation.status = 'ok';
    this.savings.moderation.error = '';
    this.savings.moderation.refresh = undefined;
  };

  errorModeration = (e, onRefresh) => {
    if (e.response) {
      const { data: { error: { message = '' } = {} } = {} } = e.response;

      this.savings.moderation.isSaving = false;
      this.savings.moderation.status = 'error';
      this.savings.moderation.error = `Ошибка публикации: ${this.getModerationError(message)}`;
      this.savings.moderation.refresh = onRefresh;
      this.setSavingError(true);
      this.setSavingModal(true);
    }
  };

  @action
  publishPublication = async () => {
    this.clearErrorModeration();
    if (this.isPublicationLockedByMe) {
      await this.savePublication();
    }
    return this.apiLayer
      .publish(this.id)
      .then(({ status }) => {
        runInAction(() => {
          this.status = status;
        });
        console.log('publishPublication...', this.id);
        return status;
      })
      .catch(e => {
        this.errorModeration(e, this.publishPublication);
      })
      .finally(() => {
        runInAction(() => {
          this.savings.moderation.isSaving = false;
        });
      });
  };

  @action
  unpublishPublication = () => {
    return this.apiLayer
      .unpublish(this.id)
      .then(({ status }) => {
        runInAction(() => {
          this.status = status;
        });
        return status;
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка снятия с публикации' }));
  };

  @action
  draftPublication = () => {
    return this.apiLayer
      .toDraft(this.id)
      .then(({ status }) => {
        runInAction(() => {
          this.status = status;
        });
        return status;
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка перевода статуса в черновик' }));
  };

  @action
  postponePublication = () => {
    this.clearErrorModeration();
    return this.apiLayer
      .postpone({ id: this.id, publicationDate: this.publicationDate })
      .then(
        ({
          data: {
            data,
            data: { status },
          },
        }) => {
          runInAction(() => {
            this.status = status;
          });
          return data;
        },
      )
      .catch(e => {
        console.error(e);
        this.errorModeration(e, this.postponePublication);
      });
  };

  @action
  previewPublication = () => {
    if (this.status.id === 'POST_STATUS_PUBLISHED') {
      return Promise.resolve(`${process.env.PUBLIC_ORIGIN}/p/${this.alias || this.index}`);
    }

    return Promise.resolve(`${process.env.PUBLIC_ORIGIN}/preview/p/${this.index}/${this.id}`);
  };

  @action
  restoreSavings = () => {
    this.savings = {
      settings: { status: 'ok' },
      moderation: { status: 'ok' },
      blocks: {
        isSaving: false,
        errors: [],
      },
    };
  };

  @action
  setSaving = saving => {
    const { setVisible, setText } = OverlayStore;
    setText('Идет сохранение публикации. Пожалуйста, подождите...');
    setVisible(saving);
    this.setIsPublicationSaving(saving);
  };

  @action
  setSavingModal = savingModal => {
    this.savingModal = savingModal;
  };

  @action
  setSavingError = saving => {
    this.savingError = saving;
  };

  getPublicationTemplateKey = () => `publication:${this.id}`;

  getTemplate = () => {
    const parseObject = tryCatch(JSON.parse, always(null));

    return parseObject(localStorage.getItem(this.getPublicationTemplateKey()));
  };

  @action
  deleteTemplate = () => {
    this.template = null;

    Object.keys(this.isForceUpdate).forEach(field => {
      this.isForceUpdate[field] = false;
    });

    localStorage.removeItem(this.getPublicationTemplateKey());
  };

  @action
  saveTemplate = debounce(async () => {
    console.log('Saving template publication');

    let settingsToTemplate = null;

    if (this.isLockedByMe(this.settingsLocked)) {
      settingsToTemplate = { ...this.asJson, alias: this.alias };
    }

    this.template = {
      blocks: this.getBlockByResource(this)
        .filter(this.isLockedByMe)
        .map(dissoc('store')),
      savedAs: moment().toISOString(),
    };

    if (settingsToTemplate) {
      this.template.settings = { ...settingsToTemplate };
    }

    localStorage.setItem(this.getPublicationTemplateKey(), JSON.stringify(this.template));
  }, TEMPLATE_INTERVAL);

  @action
  restoreTemplate = async () => {
    const publication = await this.apiLayer.fetchPublication(this.id);
    await this.syncFromServer(publication);

    const { blocks: templateBlocks, settings } = this.template;

    const settingsLockedByOthers = this.isLockedByOthers(settings);

    const blocksContent = new Map([
      ...this.publicationBlocksContent,
      ...this.mandatoryBlocksContent,
    ]);

    const blockLocks = templateBlocks
      .filter(blockTemplate => {
        const blockPublication = blocksContent.get(blockTemplate.id);
        return isNil(blockPublication) || this.isLockedByOthers(blockPublication);
      })
      .map(blockTemplate => {
        const order =
          this.publicationBlocksOrder.findIndex(blockId => blockTemplate.id === blockId) + 1;
        return {
          ...blockTemplate,
          order,
        };
      });

    if (
      (settingsLockedByOthers || blockLocks.length > 0) &&
      !(await templateRestoreModal({
        settings,
        blocks: blockLocks,
      }))
    ) {
      return;
    }

    // Восстановление блоков который не залочены
    for (const block of symmetricDifferenceWith(eqBy(prop('id')), templateBlocks, blockLocks)) {
      const oldBlock = blocksContent.get(block.id);
      await oldBlock.lock();

      if (!this.mandatoryFieldArray.includes(block.type)) {
        this.publicationBlocksContent.set(
          block.id,
          new Block(block.id, {
            ...block,
            isForceUpdate: true,
            updatedAt: oldBlock.updatedAt,
            hash: oldBlock.hash,
          }),
        );
      } else {
        this.mandatoryBlocksContent.set(
          block.id,
          new Block(block.id, {
            ...block,
            isForceUpdate: true,
            updatedAt: oldBlock.updatedAt,
            hash: oldBlock.hash,
          }),
        );
      }
    }

    if (!this.settingsLocked || !this.settingsLocked.lockedBy) {
      await this.lockSettings(false);
    }
    mapObjIndexed(async (val, key) => {
      this[key] = val;
    }, propOr({}, 'settings', this.template));

    this.deleteTemplate();
  };

  getAlias = cond([
    [isNumber, always(null)],
    [allPass([isString, isEmpty]), always(null)],
    [T, identity],
  ]);

  getField = pipe(prop('field'), field => this[field]);

  getBlockByResource = ifElse(
    propEq('resourceType', resourceTypes.textTranslation),
    pipe(
      juxt([
        pipe(prop('blockGroups'), map(prop('blocks')), flatten),
        prop('mandatoryBlocks'),
        prop('blocks'),
      ]),
      flatten,
    ),
    pipe(juxt([prop('mandatoryBlocks'), prop('blocks')]), flatten),
  );

  @action
  validatePublication = async () => {
    await timeout(2000);

    this.setIsPublicationValidating(true);

    if (this.resourceType === 'RESOURCE_TYPE_TEXT_STREAM') {
      this.setIsPublicationValidating(false);
      return true;
    }

    await Promise.all([this.validateBlocks(), this.validateFields()]);

    const {
      validationErrors: blockValidationErrors,
      globalError,
    } = this.validationResult.blockValidationErrors;
    const {
      validationErrors: fieldsValidationErrors,
    } = this.validationResult.fieldsValidationErrors;

    if (blockValidationErrors.length > 0 || globalError || fieldsValidationErrors.length > 0) {
      await openValidationModal();
      this.resetValidationErrors();
      this.setSaving(false);
      this.setIsPublicationValidating(false);
      return false;
    }

    this.setIsPublicationValidating(false);
    return true;
  };

  @action
  savePublication = async () => {
    this.setSaving(true);

    if (this.status.id === 'POST_STATUS_PUBLISHED' || this.status.id === 'POST_STATUS_POSTPONED') {
      const validationResult = await this.validatePublication();
      if (!validationResult) return validationResult;
    }

    try {
      await timeout(2000);

      const saveFieldsResult = await this.saveFields();
      if (this.isLockedByMe(this.settingsLocked)) {
        await this.unlockSettings();
      }
      const saveBlocksResult = await this.saveBlocks();

      const promises = [Promise.resolve(saveFieldsResult), Promise.resolve(saveBlocksResult)];
      await this.saveAll(promises);

      console.log('savePublication...', this.id);

      if (this.status.id === 'POST_STATUS_PUBLISHED') {
        await this.publishPublication();
      }

      await this.refreshPublication();
    } catch (e) {
      console.error('Error saving publication...', this.id, e);
      this.setSavingError(true);
      this.setSavingModal(true);
    } finally {
      this.setSaving(false);
    }

    return true;
  };

  @action
  saveFields = async () => {
    const createPromiseSaveFields = data =>
      isEmpty(data)
        ? Promise.resolve()
        : new Promise((resolve, reject) => {
            const setSavings = fn => {
              mapObjIndexed((item, key) => {
                runInAction(() => {
                  fn(has(key, this.savings) ? key : 'settings');
                });
              }, data);
            };

            setSavings(field => {
              this.savings[field].isSaving = true;
              this.savings[field].error = '';
            });

            API.patch(`/posts/${this.id}`, { data, hashes: this.hashes })
              .then(({ data: { errors = {} } = {} }) => {
                runInAction(() => {
                  forEachObjIndexed((value, field) => {
                    this.savings[field].isSaving = false;
                    if (has(field, errors)) {
                      this.savings[field].status = 'error';
                      this.savings[field].error = errors[field];
                      this.savings[field].refresh = this.saveFields;
                    } else if (has(field, data)) {
                      this.savings[field].status = 'ok';
                    }
                  }, this.savings);
                });

                if (!has('settings', errors)) {
                  this.priorities.setDraftPriorities(null);
                }

                if (isEmpty(errors)) {
                  resolve(data);
                } else {
                  reject(errors);
                }
              })
              .catch(e => {
                setSavings(field => {
                  this.savings[field].status = 'error';
                  this.savings[field].error = getErrorMessage(e);
                  this.savings[field].refresh = this.saveFields;
                });
                reject(e);
              })
              .finally(() => {
                setSavings(field => {
                  this.savings[field].isSaving = false;
                });
              });
          });

    const getFieldData = cond([
      [
        propEq('field', 'settings'),
        async ({ field }) => {
          if (!isExistsAuthors(this)) {
            runInAction(() => {
              this.savings[field].isSaving = false;
              this.savings[field].status = 'error';
              this.savings[field].error = 'Поле автор не заполнено';
              this.savings[field].refresh = this.saveFields;
            });

            this.setSavingError(true);
            this.setSavingModal(true);

            throw new Error('Поле автор не заполнено');
          }

          if (isNotNil(this.asJson.draftPriorities)) {
            try {
              const { realPriorities } = await this.apiLayer.getPostPriorities(this.id);
              this.priorities.setDraftPriorities(
                mergeDeepLeft(this.asJson.draftPriorities, realPriorities || {}),
              );
            } catch (e) {
              runInAction(() => {
                this.savings[field].isSaving = false;
                this.savings[field].status = 'error';
                this.savings[field].error = 'Ошибка загрузки приоритетов';
                this.savings[field].error = this.saveFields;
              });

              this.setSavingError(true);
              this.setSavingModal(true);

              throw e;
            }
          }

          this.alias = this.getAlias(this.alias);
          return { ...getSettingsNormalizedData(this.asJson), alias: this.alias };
        },
      ],
      [T, field => ({ [prop('field', field)]: this.getField(field) })],
    ]);

    const getPromiseSaveFields = pipe(filter(isNotNil), mergeAll, createPromiseSaveFields);

    const getSaveFields = pipe(filter(this.isLockedByMe), map(getFieldData), promiseAll);

    return getPromiseSaveFields(
      await getSaveFields([
        {
          field: 'settings',
          lockedBy: this.settingsLocked.lockedBy,
          lockedTo: this.settingsLocked.lockedTo,
        },
      ]),
    );
  };

  @action
  saveBlocks = async () => {
    const createPromiseSaveAllBlocks = async blocks => {
      this.savings.blocks.errors = [];
      this.savings.blocks.isSaving = true;

      try {
        this.apiLayer.cancelFetchPublication();
        await this.apiLayer.saveBlocks(blocks);
        await this.unlockAllMyBlocks();
      } catch (e) {
        const errorMessage = getErrorMessage(e);

        this.setSavingError(true);
        this.setSavingModal(true);

        this.savings.blocks.refresh = this.saveBlocks;
        this.savings.blocks.errors.push(`Ошибка сохранения блоков \n  ${errorMessage}`);

        showErrorNotification('Ошибка сохранения блоков', errorMessage);
        throw e;
      } finally {
        runInAction(() => {
          this.savings.blocks.isSaving = false;
        });
      }
    };

    const getSaveBlocks = pipe(
      ifElse(
        propEq('resourceType', resourceTypes.textTranslation),
        pipe(
          juxt([
            pipe(prop('blockGroups'), map(prop('blocks')), flatten),
            prop('mandatoryBlocks'),
            prop('blocks'),
          ]),
          flatten,
        ),
        pipe(juxt([prop('mandatoryBlocks'), prop('blocks')]), flatten),
      ),
      filter(this.isLockedByMe),
      map(pipe(prop('save'), callAsync)),
      promiseAll,
    );

    const getPromiseSaveBlocks = ifElse(length, createPromiseSaveAllBlocks, always(null));

    await timeout(2000);
    return getPromiseSaveBlocks(await getSaveBlocks(this));
  };

  @action
  validateBlocks = async () => {
    const getBlockOrderString = ({ type, order }) => {
      switch (type) {
        case COVER_BLOCK: {
          return '"Обложка"';
        }
        case TITLE_BLOCK: {
          return '"Заголовок"';
        }
        case SPECIAL_TITLE_BLOCK: {
          return this.isNewsSection ? '"Заголовок Яндекс.Новости"' : '"Поисковый заголовок"';
        }
        case SUBTITLE_BLOCK: {
          return '"Подзаголовок"';
        }
        case CLICKBAIT_BLOCK: {
          return '"Кликбейт"';
        }
        case TEXT_DESCRIPTION: {
          return '"Текст (description)"';
        }
        default: {
          return `№ ${order}`;
        }
      }
    };

    if (this.publicationBlocksContent.size + this.mandatoryBlocksContent.size !== 0) {
      const blocks = [
        ...this.mandatoryBlocksContent.values(),
        ...this.publicationBlocksContent.values(),
      ];
      for (let block of blocks) {
        const dataToCompile = {
          _id: block.id,
          type: block.type,
          content: block.content,
          compiled: null,
        };

        const validationResult = await v0.validateBlock(dataToCompile, {
          isNewsSection: this.isNewsSection,
        });

        if (validationResult.length > 0) {
          this.validationResult.blockValidationErrors.validationErrors.push({
            fieldId: block.id,
            blockOrderString: getBlockOrderString(block),
            blockOrder: block.order,
            errors: validationResult,
          });
        }
      }
    } else {
      this.validationResult.blockValidationErrors.globalError = 'В публикации отсутствуют блоки!';
    }
  };

  @action
  validateFields = async () => {
    const fieldsToValidate = [
      {
        fieldType: 'AUTHORS_FIELD',
        fieldData: this.authors,
        fieldName: 'Автор',
      },
    ];

    for (let field of fieldsToValidate) {
      const { _id = 'noId', fieldType, fieldName } = field;

      let fieldData = field.fieldData;

      if (!fieldData) {
        fieldData = { text: '' };
      }

      const dataToCompile = {
        _id: _id,
        type: fieldType,
        content: JSON.stringify(toJS(fieldData)),
        compiled: null,
      };

      const validationResult = await v0.validateField(dataToCompile);

      if (validationResult.length > 0) {
        this.validationResult.fieldsValidationErrors.validationErrors.push({
          fieldId: _id,
          blockOrderString: fieldName,
          errors: validationResult,
        });
      }
    }
  };

  @action
  saveAll = (promises = []) => {
    if (!Array.isArray(promises) || promises.length < 1) {
      return Promise.reject(new Error('Not array or []'));
    }

    return Promise.allSettled(promises)
      .then(res => {
        res.forEach(({ status, reason, value }) => {
          if (status === 'rejected' || value?.response?.status !== 200) {
            if (reason) {
              this.setSavingError(true);
              this.setSavingModal(true);
              throw new Error(reason);
            }
          }
        });
      })
      .then(() => {
        notification.success({ message: 'Изменения сохранены' });
      })
      .catch(e => {
        this.setSavingError(true);
        this.setSavingModal(true);
        return Promise.reject(e);
      })
      .finally(() => {
        this.setSaving(false);
      });
  };

  /**
   * Blocks
   */

  /*
  setBlocksFromServer(json) {
    let block = this.findBlockById(json._id)
    if (!block) {
      block = new Block(this, json._id, { ...json })
      this.blocks.push(block)
    }
  }
  */

  setBlockGroupsFromServer(json) {
    let group = this.findBlockGroupById(json._id);
    if (!group) {
      group = new BlockGroup(this, json._id, { ...json });
      this.blockGroups.push(group);
    }
  }

  findBlockById(blockId) {
    const blocksContent = new Map([
      ...this.publicationBlocksContent,
      ...this.mandatoryBlocksContent,
    ]);
    return blocksContent.get(blockId);
  }

  findBlockGroupById(groupId) {
    return this.blockGroups.find(group => group.id === groupId);
  }

  @action
  save = data => {
    this.isLoading = true;
    return this.apiLayer.save({ data, id: this.id }).finally(() => {
      this.isLoading = false;
    });
  };

  delayedExecute = (f, delay) => {
    let timerId = null;

    return value => {
      clearTimeout(timerId);

      timerId = setTimeout(() => {
        f(value);
      }, delay);
    };
  };

  saveDelayed = this.delayedExecute(this.save, 600);

  @action
  setPublicationDate = date => {
    this.publicationDate = date;
  };

  @action
  setValidatedBy = validatedBy => {
    this.validatedBy = { ...this.validatedBy, ...validatedBy };
    // this.save({ validatedBy: this.validatedBy })
  };

  @action
  setValidatedAt = validatedAt => {
    this.validatedAt = { ...this.validatedAt, ...validatedAt };
    // this.save({ validatedBy: this.validatedBy })
  };

  @action
  approve = approvedBy => {
    this.apiLayer.cancelFetchPublication();
    // eslint-disable-next-line no-param-reassign
    approvedBy = typeof approvedBy === 'string' ? approvedBy : Object.keys(approvedBy)[0];
    this.setValidatedBy({ [approvedBy]: true });
    this.setValidatedAt({ [approvedBy]: new Date() });
    return this.apiLayer
      .approve({ id: this.id, approvedBy: { [approvedBy]: true } })
      .then(
        ({
          data: {
            data: { validatedBy, validatedAt, blocks },
          },
        }) => {
          this.setValidatedBy(Object.keys(validatedBy).map(key => ({ [key]: true })));
          this.setValidatedAt(Object.keys(validatedAt).map(key => ({ [key]: new Date() })));
          blocks.forEach(({ id }) => {
            this.approveBlockById(id, validatedBy, validatedAt);
          });
        },
      )
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка валидации публикации',
          callback: () => {
            this.setValidatedBy({ [approvedBy]: false });
            this.setValidatedAt({ [approvedBy]: null });
          },
        }),
      );
  };

  @action
  approveBlock = async (blockId, approvedBy) => {
    this.apiLayer.cancelFetchPublication();
    // eslint-disable-next-line no-param-reassign
    approvedBy = typeof approvedBy === 'string' ? approvedBy : Object.keys(approvedBy)[0];
    return await this.apiLayer
      .approveBlock({ id: blockId, approvedBy: { [approvedBy]: true } })
      .then(({ validatedBy, validatedAt }) => {
        this.approveBlockById(blockId, validatedBy, validatedAt);
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка валидации блока' }));
  };

  @action
  saveDraftPriorities = async () => {
    try {
      // await this.lockField({ field: 'settings' });
      await this.lockSettings();
      this.priorities.savePriority();
      this.hideModalPriority();
    } catch (e) {
      showErrorNotification('Ошибка сохранения приоритетов', e);
    }
  };

  @action
  setSection = async section => {
    // await this.lockField({ field: 'settings' });
    await this.lockSettings();
    runInAction(() => {
      if (typeof section === 'string') {
        this.section = section;
      } else if (section.key) {
        this.section = section.key;
        if (section.label === 'Дичь') {
          // this.flags.criminalContent = true
          // this.flags.darkSide = true;
        }
      }
      /* Deprecated
        this.priorities.setSectionPriority(0);
        this.priorities.savePriority();
      */
      this.saveTemplate();
    });
  };

  @action
  setRss = async rss => {
    // await this.lockField({ field: 'settings' });
    await this.lockSettings();
    runInAction(() => {
      this.flags = {
        ...this.flags,
        RSS: this.rssTypes.reduce(
          (newRss, rssType) => ({ ...newRss, [rssType.id]: rss.includes(rssType.id) }),
          {},
        ),
      };
      this.saveTemplate();
    });
    // this.save({ flags })
  };

  @action
  set = async ({ name, value }, isRestoreTemplate = false) => {
    switch (name) {
      default:
        // await this.lockField({ field: 'settings' });
        await this.lockSettings();
        runInAction(() => {
          this[name] = value;
        });
        break;
    }
    !isRestoreTemplate && this.saveTemplate();
  };

  @action setFlags = async flags => {
    // await this.lockField({ field: 'settings' });
    await this.lockSettings();
    runInAction(() => {
      const newFlags = toJS(this.flags);

      Object.keys(newFlags).forEach(key => {
        newFlags[key] = flags.indexOf(key) > -1;
      });

      this.flags = {
        ...newFlags,
        // isWide: flags.indexOf('isWide') > -1,
        RSS: { ...this.flags.RSS },
      };
      this.saveTemplate();
      // this.save({ flags: newFlags })
    });
  };

  /*
  reorderBlocks = (order = this.blocksOrder) => {
    this.blocks.replace(
      this.blocks.slice().sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
    )
  }
  */

  @action
  createBlock = ({ before, type, data = null, ...rest }, initialProps) => {
    this.apiLayer.cancelFetchPublication();
    return this.apiLayer
      .createBlock({
        before,
        data: { type, data: data || initialProps || null, ...rest },
      })
      .then(({ _id, ...rest }) => {
        this.addBlock({ _id, before, ...rest });
      })
      .catch(reason => {
        showErrorNotification('Ошибка создания блока', reason);
        return Promise.reject(reason);
      });
  };

  @action
  createBlocksFromArray = async (blocks, before) => {
    blocks = blocks.filter(({ charactersCount }) => charactersCount > 0);

    const placementIndex = this.publicationBlocksOrder.indexOf(before);
    this.apiLayer.cancelFetchPublication();

    const { setVisible, setText } = OverlayStore;
    setText('Идет создание блоков публикации. Пожалуйста, подождите...');
    setVisible(true);
    this.setIsPublicationSaving(true);

    try {
      const newBlocks = await this.apiLayer.createBlocks(
        blocks.map(block => ({
          before,
          locked: true,
          data: { type: block.type, data: null },
        })),
      );

      for (const [i, { _id, ...rest }] of newBlocks.entries()) {
        this.addBlock({ _id, before, ...rest, content: JSON.stringify(blocks[i]) }, placementIndex);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      showErrorNotification('Ошибка создания блоков', errorMessage);
    } finally {
      setVisible(false);
      this.setIsPublicationSaving(false);
    }
  };

  insertBlockToOrder(blockId, beforeBlockId = null) {
    if (beforeBlockId === null) {
      this.publicationBlocksOrder.push(blockId);
    } else {
      const insertPosition = this.publicationBlocksOrder.indexOf(beforeBlockId);
      this.publicationBlocksOrder.splice(insertPosition, 0, blockId);
    }
  }

  removeBlockFromOrder(blockId) {
    const removePosition = this.publicationBlocksOrder.indexOf(blockId);
    this.publicationBlocksOrder.splice(removePosition, 1);
  }

  reorderBlock(blockId, beforeBlockId) {
    this.removeBlockFromOrder(blockId);
    this.insertBlockToOrder(blockId, beforeBlockId);
  }

  addBlock = ({ _id: id, before = null, ...rest }) => {
    if (this.publicationBlocksContent.has(id)) return;

    this.publicationBlocksContent.set(id, new Block(id, rest));
    this.insertBlockToOrder(id, before);
  };

  @action
  updateBlock = async ({ id, data }) => {
    const block = this.findBlockById(id);
    if (block) {
      if (!block.lockedBy?._id) {
        this.apiLayer.cancelFetchPublication();
        await block.lock();
      }
      block.setContent(data);
      this.saveTemplate();
    } else {
      console.error('Block not found');
    }
  };

  @action
  saveBlock = blockId => {
    const block = this.findBlockById(blockId);
    if (block) {
      this.isLoading = true;
      block.save().then(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
    } else {
      console.error('Block not found');
    }
  };

  @action
  deleteBlockById = blockId => {
    const block = this.findBlockById(blockId);
    if (block) {
      this.apiLayer.cancelFetchPublication();
      block.delete();
    }
    // this.blocks.remove(block)
    // block.dispose()
  };

  @action
  getBlockOrderById = blockId => {
    return this.publicationBlocksOrder.indexOf(blockId) + 1;
  };

  @action
  removeBlock = block => {
    this.publicationBlocksContent.delete(block.id);
    /*
    this.blocks.remove(block)
    */
    block.dispose();
  };

  @action
  lockBlock = async blockId => {
    const blocksContent = new Map([
      ...this.publicationBlocksContent,
      ...this.mandatoryBlocksContent,
    ]);
    if (blocksContent.has(blockId)) {
      const block = blocksContent.get(blockId);

      if (this.isNotLocked(block)) {
        try {
          this.apiLayer.cancelFetchPublication();
          return await block.lock();
        } catch (e) {
          showErrorNotification('Ошибка блокировки блока', e);
        }
      }
    }
  };

  @action
  unlockBlock = async blockId => {
    const blocksContent = new Map([
      ...this.publicationBlocksContent,
      ...this.mandatoryBlocksContent,
    ]);
    blocksContent.has(blockId);
    {
      const block = blocksContent.get(blockId);
      try {
        this.apiLayer.cancelFetchPublication();
        await block.unlock();
      } catch (e) {
        showErrorNotification('Ошибка разблокировки блока', e);
      }
    }
  };

  @action
  changeBlockPosition = async ({ id: blockId, before, prevBefore }) => {
    try {
      this.apiLayer.cancelFetchPublication();
      this.apiLayer.moveBlock({ id: blockId, before }).catch(e => {
        this.reorderBlock(blockId, prevBefore);
        showErrorNotification('Ошибка сортировки блоков', e);
      });
      this.reorderBlock(blockId, before);
    } catch (e) {
      showErrorNotification('Ошибка сортировки блоков', e);
    }
    /*
    const newBlocksOrder = this.blocksOrder.slice()
    const draggableIndex = this.blocksOrder.findIndex(id => id === blockId)

    if (draggableIndex === -1) return

    newBlocksOrder.splice(draggableIndex, 1)
    if (before == null) {
      newBlocksOrder.push(blockId)
    } else {
      const placementIndex = newBlocksOrder.findIndex(id => id === before)
      newBlocksOrder.splice(placementIndex, 0, blockId)
    }

    const newBlocks = this.blocks.slice()

    this.blocks = newBlocks.sort(
      (a, b) => newBlocksOrder.indexOf(a.id) - newBlocksOrder.indexOf(b.id)
    )
    */

    // .then(() => {
    //   const elementRect = document.activeElement.getBoundingClientRect()
    //   const absoluteElementTop = elementRect.top + window.pageYOffset
    //   const middle = absoluteElementTop - window.innerHeight / 2
    //   window.scrollTo(0, middle)
    // })
  };

  @action
  createBlockGroup = () => {
    this.apiLayer.createBlockGroup().then(({ _id, ...rest }) => {
      const group = new BlockGroup(this, _id, rest);

      const newGroups = [group, ...this.blockGroups];
      runInAction(() => {
        this.blockGroups = newGroups;
        // this.apiLayer.save({ id: this.id, data: {} })
      });
    });
  };

  @action
  removeGroup = group => {
    this.blockGroups.remove(group);
    // this.apiLayer.save({ id: this.id, data: {} })
  };

  @action
  openCommentsModal = () => {
    const { setOpenedPost } = PublicationCommentsStore;

    setOpenedPost({
      id: this.id,
      title: this.title,
    });
  };

  @action
  unlockAllMyBlock = () => {
    return this.apiLayer.unlockAllMyBlock();
  };

  @action
  unlockAllMyBlocks = async () => {
    this.apiLayer.cancelFetchPublication();
    await this.apiLayer.unlockAllMyBlocks();
    runInAction(() => {
      pipe(
        filter(this.isLockedByMe),
        map(invoker(0, 'setLockedToNoone')),
      )([...this.blocks, ...this.mandatoryBlocks]);
    });
  };

  @action
  showSettingsDrawer = () => {
    this.isShowSettingsDrawer = true;
  };

  @action
  hideSettingsDrawer = () => {
    this.isShowSettingsDrawer = false;
  };

  @action
  setFetching = request => {
    this.fetchingRequests.push(request);
  };

  @action
  resetValidationErrors = () => {
    this.validationResult = {
      blockValidationErrors: {
        globalError: null,
        validationErrors: [],
      },
      fieldsValidationErrors: {
        validationErrors: [],
      },
    };
  };

  @action
  setFetchingComplete = request => {
    this.fetchingRequests.remove(request);
  };

  @action
  startPublicationTimer = () => {
    this.clearPublicationTimer();
    if (!isEmpty(this.id)) {
      this.publicationTimer = setTimeout(this.refreshPublication, PUBLICATION_INTERVAL);
    }
  };

  @action
  clearPublicationTimer = () => {
    clearInterval(this.publicationTimer);
  };

  getServerId = ({ _id }) => _id;

  getId = ({ id }) => id;

  getMe = () => path(['_id'], this.me);

  isLocked = pipe(prop('lockedBy'), isNotNil);

  isNotLocked = pipe(prop('lockedBy'), isNil);

  isMe = pipe(juxt([path(['lockedBy', '_id']), this.getMe]), apply(equals));

  isNotMe = complement(this.isMe);

  isLockedByMe = allPass([this.isLocked, this.isMe]);

  isLockedByOthers = allPass([this.isLocked, this.isNotMe]);

  isNotLockedByMe = anyPass([this.isNotLocked, this.isLockedByOthers]);

  createBlockModel = serverBlock => new Block(this.getServerId(serverBlock), serverBlock);

  createBlockGroupModel = serverBlock =>
    new BlockGroup(this, this.getServerId(serverBlock), serverBlock);

  setLockDataFromStore = serverLock => {
    const storeLock = this.locks.find(storeLock => storeLock.field === serverLock.field);
    if (storeLock) {
      return {
        ...serverLock,
        hash: storeLock.hash,
      };
    }
    return { ...serverLock };
  };

  setBlockDataFromStore = serverBlock => {
    const storeBlock = [...this.blocks, ...this.mandatoryBlocks].find(
      storeBlock => this.getServerId(serverBlock) === this.getId(storeBlock),
    );
    if (storeBlock) {
      return {
        ...serverBlock,
        data: storeBlock.data,
        content: storeBlock.content,
        hash: storeBlock.hash,
      };
    }
    return { ...serverBlock };
  };

  setBlockDataFromGroupStore = blockGroup => block => {
    const storeBlockGroup = this.blockGroups.find(
      storeBlockGroup => this.getServerId(blockGroup) === this.getId(storeBlockGroup),
    );

    const storeBlockLockedByMe = storeBlockGroup.blocks.find(
      storeBlock => this.getServerId(block) === this.getId(storeBlock),
    );

    return {
      ...block,
      data: storeBlockLockedByMe?.data,
      content: storeBlockLockedByMe?.content,
    };
  };

  isLockedSettings = includes('settings');

  approveBlockById = (blockId, validatedBy, validatedAt) => {
    const block = this.findBlockById(blockId);
    if (block) {
      block.validatedBy = { ...block.validatedBy, ...validatedBy };
      block.validatedAt = { ...block.validatedAt, ...validatedAt };
      block.isCorrect = !!validatedBy.corrector;
    }
  };

  get hashes() {
    return { settings: this.settingsLocked.hash };
  }
}

export default PublicationStore;
